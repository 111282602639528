import { errorMessages } from '@common/intl'
import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { get, round } from 'lodash-es'
import React, { useMemo } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types/path'
import { useIntl } from 'react-intl'

function setPropsLoading(props: TextFieldProps) {
  props.select = false
  props.disabled = true
  props.InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <CircularProgress size="1em" color="primary" />
      </InputAdornment>
    ),
  }
}

export interface FormOption {
  value: string
  label: string | React.ReactNode
}

export type FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'required'> &
  UseControllerProps<TFieldValues, TFieldName> & {
    required?:
      | boolean
      | ((
          value: FieldPathValue<TFieldValues, TFieldName>,
          values: TFieldValues,
        ) => boolean)
    numberRounding?: number
  }

export function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules: propRules,
  shouldUnregister,
  defaultValue,
  control,
  error,
  helperText,
  onChange,
  required,
  numberRounding,
  ...props
}: FormTextFieldProps<TFieldValues, TFieldName>) {
  const intl = useIntl()
  const form = useFormContext()
  const formError = get(form.formState.errors, name) as any

  const rules = useMemo(
    (): UseControllerProps<TFieldValues, TFieldName>['rules'] =>
      propRules || required
        ? {
            ...propRules,
            ...(required
              ? typeof required === 'boolean'
                ? required
                  ? {
                      required: intl.formatMessage(errorMessages.requiredField),
                    }
                  : {}
                : typeof required === 'function'
                  ? {
                      validate: (value, values) => {
                        const isRequired = required(value, values)
                        if (isRequired && !value) {
                          return intl.formatMessage(errorMessages.requiredField)
                        }

                        return undefined
                      },
                    }
                  : {}
              : {}),
          }
        : undefined,
    [propRules, required, intl],
  )

  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control! || form.control}
      render={({ field: { ref, value, ...field } }) => {
        // To signalize loading
        if (props.children === undefined && props.select) {
          setPropsLoading(props)
        }

        const textFieldValue =
          typeof value === 'number' && numberRounding !== undefined
            ? round(value, numberRounding)
            : value

        return (
          <TextField
            {...props}
            {...field}
            value={textFieldValue}
            disabled={field.disabled || props.disabled}
            onChange={(e) => {
              field.onChange(e)
              onChange?.(e)
            }}
            slotProps={{
              ...props.slotProps,
              inputLabel: {
                ...props.slotProps?.inputLabel,
                required:
                  !!rules?.required ||
                  (typeof rules?.validate === 'function'
                    ? !!rules?.validate?.(
                        value,
                        form.getValues() as TFieldValues,
                      )
                    : false),
              },
            }}
            inputRef={ref}
            error={error !== undefined ? error : !!formError}
            helperText={formError?.message ?? helperText}
          />
        )
      }}
    />
  )
}

FormTextField.displayName = 'FormTextField'
export default FormTextField
